@import '~antd/dist/antd.css';

html {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}
body {
    overflow: hidden;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
}

.m-0 { margin: 0; }
.m-1 { margin: 4px; }
.m-2 { margin: 8px; }
.m-3 { margin: 12px; }
.m-4 { margin: 16px; }

.mt-0 { margin-top: 0; }
.mt-1 { margin-top: 4px; }
.mt-2 { margin-top: 8px; }
.mt-3 { margin-top: 12px; }
.mt-4 { margin-top: 16px; }

.mr-0 { margin-right: 0; }
.mr-1 { margin-right: 4px; }
.mr-2 { margin-right: 8px; }
.mr-3 { margin-right: 12px; }
.mr-4 { margin-right: 16px; }

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: 4px }
.mb-2 { margin-bottom: 8px }
.mb-3 { margin-bottom: 12px }
.mb-4 { margin-bottom: 16px }

.ml-0 { margin-left: 0; }
.ml-1 { margin-left: 4px; }
.ml-2 { margin-left: 8px; }
.ml-3 { margin-left: 12px; }
.ml-4 { margin-left: 16px; }

.my-0 { margin-top: 0; margin-bottom: 0; }
.my-1 { margin-top: 4px; margin-bottom: 4px; }
.my-2 { margin-top: 8px; margin-bottom: 8px; }
.my-3 { margin-top: 12px; margin-bottom: 12px; }
.my-4 { margin-top: 16px; margin-bottom: 16px; }

.mx-0 { margin-right: 0; margin-left: 0; }
.mx-1 { margin-right: 4px; margin-left: 4px; }
.mx-2 { margin-right: 8px; margin-left: 8px; }
.mx-3 { margin-right: 12px; margin-left: 12px; }
.mx-4 { margin-right: 16px; margin-left: 16px; }

.p-0 { padding: 0; }
.p-1 { padding: 4px }
.p-2 { padding: 8px }
.p-3 { padding: 12px }
.p-4 { padding: 16px }

.pt-0 { padding-top: 0; }
.pt-1 { padding-top: 4px; }
.pt-2 { padding-top: 8px; }
.pt-3 { padding-top: 12px; }
.pt-4 { padding-top: 16px; }

.pr-0 { padding-right: 0; }
.pr-1 { padding-right: 4px; }
.pr-2 { padding-right: 8px; }
.pr-3 { padding-right: 12px; }
.pr-4 { padding-right: 16px; }

.pb-0 { padding-bottom: 0; }
.pb-1 { padding-bottom: 4px; }
.pb-2 { padding-bottom: 8px; }
.pb-3 { padding-bottom: 12px; }
.pb-4 { padding-bottom: 16px; }

.pl-0 { padding-left: 0; }
.pl-1 { padding-left: 4px; }
.pl-2 { padding-left: 8px; }
.pl-3 { padding-left: 12px; }
.pl-4 { padding-left: 16px; }

.py-0 { padding-top: 0; padding-bottom: 0; }
.py-1 { padding-top: 4px; padding-bottom: 4px; }
.py-2 { padding-top: 8px; padding-bottom: 8px; }
.py-3 { padding-top: 12px; padding-bottom: 12px; }
.py-4 { padding-top: 16px; padding-bottom: 16px; }

.px-0 { padding-right: 0; padding-left: 0; }
.px-1 { padding-right: 4px; padding-left: 4px; }
.px-2 { padding-right: 8px; padding-left: 8px; }
.px-3 { padding-right: 12px; padding-left: 12px; }
.px-4 { padding-right: 16px; padding-left: 16px; }
@primary-color: #999999;@error-color: #DC143C;@text-color: #6C6D72;