.widget-wrapper {
    overflow: hidden;
    display: block;
    // width: 100%;
    // height: 100%;

    .widget-container {
        position: absolute;
        overflow: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        -webkit-box-pack: start;

        .widget-header {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;

            height: 75px;
            background: linear-gradient(90.01deg, #6800D0 15.73%, #410083 99.42%);
            font-size: 16px;
            color: white;
            transition: height 160ms ease-out 0s;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 0;
                pointer-events: none;
                transition: opacity 100ms ease-in 0s;
            }

            &> div.widget-header-col {
                display: inline-block;
            }

            .button-wrapper {
                display: inline-block;
                z-index: 2147483000;
                margin: 0 8px 0 8px;

                .button-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    text-align: center;
                    box-sizing: border-box;
                    width: 51px;
                    height: 51px;
                    margin: 0px auto;
                    padding: 8px;
                    border-radius: 8px;
                    transform: translateZ(0px);

                    cursor: pointer;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.5);
                    }
                }    
            }
        }

        .widget-body {
            position: relative;
            flex: 1 1 0%;
            background-color: white;
            font-size: 14px;
            box-shadow: rgb(0 0 0 / 20%) 0px 21px 4px -20px inset;

            .widget-body-container {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                p:first-child {
                    margin-block-start: 0;
                }

                .btn-custom {
                    height: 40px;
                    padding: 8px 16px 8px 16px;
                    border-radius: 4px;
                }

                .widget-body-container-padder {
                    width: 100%;
                    height: 100%;
                    padding: 16px 16px 16px 16px;
                }

                #widget-step1 {
                    overflow: auto;
                    width: 100%;
                    height: 100%;

                    .prechat-greeting-container {
                        border-bottom: 1px solid #EBECFF;
                        margin-bottom: 20px;
                    }

                    .channels {
                        .channel {
                            background: #FFF;
                            border: 1px solid #EBECFF;
                            margin: 12px 0 6px;
                            padding: 12px 16px;
                            cursor: pointer;
        
                            .channel-name {
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 1.5;
                                color: #6800d0;
                            }
        
                            .channel-helper-text {
                                margin: 4px 0 0 0;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 1.25;
                                color: #767990;
                            }
        
                            .channel-button {
                                display: block;
                                width: 100%;
                                cursor: pointer;
                                background: #6800D0;
                                border: 0 none;
                                color: #FFFFFF;
                            }
                        }
                    }
                }

                #widget-step2 {
                    overflow: auto;
                    width: 100%;
                    height: 100%;

                    .prechat-greeting-container {
                        border-bottom: 1px solid #EBECFF;
                        margin-bottom: 20px;
                    }

                    .ant-form-item {
                        &.ant-form-item-has-error .ant-input {
                            border: 1px solid #FF4D4F;
                        }

                        .ant-form-item-explain-error {
                            display: none;
                        }

                        .ant-form-item-label {
                            padding: 0 0 4px 0;
                            line-height: 1.25;
                        
                            label {
                                height: auto;
                            }
                        }

                        .ant-input,
                        .ant-select-selector {
                            background: rgb(250, 250, 250);
                            appearance: none;
                            box-sizing: border-box;
                            box-shadow: rgb(0 0 0 / 7%) 0px 1px 3px 0px inset;
                            border-radius: 4px;
                            border: 1px solid rgb(225, 225, 225);
                            height: 40px;
                            padding: 11px 40px 11px 16px;
                            color: rgb(0, 0, 0);
                        }

                        .ant-select-selector {
                            .ant-select-selection-item,
                            .ant-select-selection-placeholder {
                                line-height: 1;
                            }
                        }

                        textarea.ant-input {
                            height: 105px;
                        }

                        button {
                            display: block;
                            width: 100%;
                            background: #6800D0;
                            border: 0 none;
                            color: #FFFFFF;
                        }
                    }
                }

                #widget-step3 {
                    overflow: auto;
                    width: 100%;
                    height: 100%;

                    .box {
                        display: flex;
                        flex-flow: column wrap;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        text-align: center;

                        .helper-text {
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 1.25;
                            color: #767990;
                        }

                        .btn-primary {
                            background: #6800D0;
                            border: 0 none;
                            color: #FFFFFF;
                        }

                        .btn-secondary {
                            background: #efefef;
                            border: 0 none;
                            color: #999;
                        }
                    }
                }

                #widget-step4 {
                    overflow: hidden;
                    width: 100%;
                    height: 100%;

                    .box {
                        display: flex;
                        flex-flow: column wrap;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        text-align: center;

                        .helper-text {
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 1.25;
                            color: #767990;
                        }

                        .btn-primary {
                            background: #6800D0;
                            border: 0 none;
                            color: #FFFFFF;
                        }

                        .btn-secondary {
                            background: #efefef;
                            border: 0 none;
                            color: #999;
                        }
                    }

                    .chat-log-container {
                        overflow: auto;
                        width: 100%;
                        height: calc(100% - 56px);

                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        padding: 16px 16px 16px 16px;

                        .chat-log-message-wrapper {
                            clear: both;
                            width: 100%;
                            margin: 0 0 0 0;
                            padding: 0px 0px 12px 0;

                            &:before, &:after {
                                clear: both;
                                content: " ";
                                display: table;
                            }
                            
                            .chat-log-message-container {
                                clear: both;
                                position: relative;
                                width: calc(100% - 48px);
                                text-align: left;

                                .message-text {
                                    padding: 16px 24px;
                                    border-radius: 5px;
                                    font-size: 14px;
                                    line-height: 1.4;
                                }

                                .message-file {
                                    padding: 4px 4px 4px 4px;
                                    border-radius: 5px;
                                    font-size: 14px;
                                    line-height: 1.4;

                                    .message-file--image {
                                        width: 100%;
                                        border-radius: 5px;
                                    }

                                    .message-file---actions {
                                        position: absolute;
                                        bottom: 22px;
                                        right: 32px;

                                        button.action {
                                            background: rgba(0, 0, 0, 0.5);
                                            border: 0 none;
                                            border-radius: 5px;
                                            color: #FFF;
                                        }
                                    }
                                }

                                .message-carousel {
                                    padding: 4px 4px 4px 4px;
                                    border-radius: 5px;
                                    font-size: 14px;
                                    line-height: 1.4;

                                    .ant-carousel .slick-prev,
                                    .ant-carousel .slick-next,
                                    .ant-carousel .slick-prev:hover,
                                    .ant-carousel .slick-next:hover {
                                      font-size: inherit;
                                      color: currentColor;
                                    }
                                    
                                    .carousel-item {
                                        .carousel-item--image {
                                            width: 100%;
                                            border-radius: 5px;
                                        }

                                        .carousel-item--title {
                                            margin: 4px 0 0 0;
                                            padding: 4px 6px 4px 6px;
                                            font-weight: 700;
                                            font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                                            font-size: 14px;
                                            line-height: 1.25;
                                            color: #333;
                                        }

                                        .carousel-item--description {
                                            margin: 4px 0 0 0;
                                            padding: 4px 6px 4px 6px;
                                            font-size: 14px;
                                            line-height: 1.25;
                                            color: #333;
                                        }

                                        .carousel-item--button-group {
                                            margin: 4px 0 0 0;
                                            padding: 10px 6px 10px 6px;

                                            .btn-action {
                                                display: block;
                                                width: calc(100% - 8px);
                                                background: #EBECFF;
                                                border: 0 none;
                                                height: auto;
                                                margin: 0 4px 8px 4px;
                                                padding: 8px 16px;
                                                font-size: 13px;
                                                line-height: 1;
                                                color: #40456B;
                                                cursor: pointer;
        
                                                &:hover {
                                                    background: darken(#EBECFF, 5%);
                                                }
        
                                                &.type-20 {
                                                    background: #D4F1F4;
                                                    color: #05445E;
        
                                                    &:hover {
                                                        background: darken(#D4F1F4, 5%);
                                                    }
                                                }
        
                                                &.type-30 {
                                                    background: #F6EFFD;
                                                    color: #6800D0;
        
                                                    &:hover {
                                                        background: darken(#F6EFFD, 5%);
                                                    }
                                                }
        
                                                &.type-90 {
                                                    background: rgba(255, 89, 86, 0.05);
                                                    color: #FF5956;
        
                                                    &:hover {
                                                        background: rgba(255, 89, 86, 0.15);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .message-button-group {
                                    .btn-action {
                                        display: inline-block;
                                        background: #EBECFF;
                                        border: 0 none;
                                        border-radius: 100px;
                                        height: auto;
                                        margin: 0 4px 8px 4px;
                                        padding: 16px 24px;
                                        font-size: 13px;
                                        line-height: 1;
                                        color: #40456B;
                                        cursor: pointer;

                                        &:hover {
                                            background: darken(#EBECFF, 5%);
                                        }

                                        &.type-20 {
                                            background: #D4F1F4;
                                            color: #05445E;

                                            &:hover {
                                                background: darken(#D4F1F4, 5%);
                                            }
                                        }

                                        &.type-30 {
                                            background: #F6EFFD;
                                            color: #6800D0;

                                            &:hover {
                                                background: darken(#F6EFFD, 5%);
                                            }
                                        }

                                        &.type-90 {
                                            background: rgba(255, 89, 86, 0.05);
                                            color: #FF5956;

                                            &:hover {
                                                background: rgba(255, 89, 86, 0.15);
                                            }
                                        }
                                    }
                                }
                            }

                            &.chat-log-message-wrapper--center {
                                float: none;
                                text-align: center;
                                margin: 12px 0 0 0;

                                .chat-log-message-container {
                                    margin: 0 auto 0 auto;
                                }
                            }

                            &.chat-log-message-wrapper--left {
                                float: left;

                                .chat-log-message-container {
                                    float: left;
                                    position: relative;
                                    padding-left: 45px;

                                    .message-by--avatar {
                                        position: absolute;
                                        left: 0;
                                        bottom: 28px;

                                        .avatar {
                                            background: rgb(255, 217, 103);
                                            color: rgb(0, 0, 0);
                                        }
                                    }
                                    
                                    .message-text {
                                        float: left;
                                        background-color: rgb(245, 245, 245);
                                        color: #000000;
                                    }

                                    .message-file {
                                        position: relative;
                                        float: left;
                                        background-color: rgb(245, 245, 245);
                                        color: #000000;

                                        .message-file---actions {
                                            bottom: 16px;
                                            right: 16px;
                                        }
                                    }

                                    .message-carousel {
                                        background-color: rgb(245, 245, 245);
                                        color: #000000;
                                    }

                                    .message-details {
                                        clear: both;
                                        padding: 4px 0 0 0;
                                        font-size: 12px;
                                        color: rgb(115, 115, 118);
                                    }
                                }
                            }

                            &.chat-log-message-wrapper--right {
                                float: right;
                                margin-left: auto;

                                .chat-log-message-container {
                                    float: right;
                                    
                                    .message-text {
                                        float: right;
                                        background-color: #F6EFFD;
                                        color: #000000;
                                    }

                                    .message-file {
                                        float: right;
                                        background-color: #F6EFFD;
                                        color: #000000;

                                        .message-file---actions {
                                            bottom: 32px;
                                            right: 16px;
                                        }
                                    }

                                    .message-details {
                                        float: right;
                                        clear: both;
                                        padding: 4px 0 0 0;
                                        font-size: 12px;
                                        color: rgb(115, 115, 118);
                                    }
                                }
                            }

                            &:last-child .chat-log-message-container .message-text {
                                margin: 0 0 0 0;
                            }
                        }

                        .chat-log-container--bottom {
                            height: 1px;
                        }
                    }

                    .chat-message-composer-container {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        min-height: 56px;
                        max-height: 200px;
                        background: #FFFFFF;
                        border-top: 1px solid rgb(230, 230, 230);
                        border-radius: 0px 0px 6px 6px;

                        .chat-message-composer--form {
                            .chat-message-composer--form-item {
                                margin-bottom: 0;

                                .chat-message-composer--textarea {
                                    width: 100%;
                                    height: 100%;
                                    outline-offset: -5px;
                                    resize: none;
                                    box-sizing: border-box;
                                    margin: 0 0 0 0;
                                    padding: 18px 110px 18px 30px;
                                    transition: 200ms ease 0s, box-shadow 200ms ease 0s;
                                    border: 0 none;
                                    outline: none !important;
                                    box-shadow: none !important;
                                    font-size: 14px;
                                    font-weight: normal;
                                    line-height: 1.25;
                                    white-space: pre-wrap;
                                    overflow-wrap: break-word;
                                    color: #000000;
                                }

                                .ant-form-item-explain {
                                    display: none;
                                }
                            }
                        }

                        .chat-message-composer--buttons {
                            position: absolute;
                            top: 0;
                            right: 16px;

                            button.btn-icon {
                                margin: 12px 0 0 0;
                                padding: 4px 3px 4px 3px;
                                border: 0 !important;
                                outline: none !important;
                                box-shadow: none !important;
                                color: #AAA;

                                &:after {
                                    display: none;
                                }

                                &:focus, &:active {
                                    outline: none !important;
                                    border: none !important;
                                    box-shadow: none !important;
                                }

                                &:hover {
                                    color: #6800d0;
                                }

                                &.btn-icon--send {
                                    padding: 4px 6px 4px 8px;
                                    font-size: 20px;
                                    color: #6800d0;
                                }
                            }
                        }

                        .chat-message-composer--emojiPicker-container {
                            position: absolute;
                            bottom: 16px;
                            right: 80px;
                        }

                        .chat-message-composer--fileUpload-container {
                            display: none;
                        }
                    }
                }

                #widget-step5 {
                    overflow: auto;
                    width: 100%;
                    height: 100%;

                    .box {
                        display: flex;
                        flex-flow: column wrap;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        text-align: center;

                        .helper-text {
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 1.25;
                            color: #767990;
                        }

                        .btn-primary {
                            background: #6800D0;
                            border: 0 none;
                            color: #FFFFFF;
                        }

                        .btn-secondary {
                            background: #efefef;
                            border: 0 none;
                            color: #999;
                        }
                    }
                }
            }
        }
    }
}


@primary-color: #999999;@error-color: #DC143C;@text-color: #6C6D72;